.awssld__content {
  background-color: transparent;
}

.awssld__bullets {
  bottom: -20px;
}
.awssld__bullets button {
  width: 10px;
  height: 10px;
}
